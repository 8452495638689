import React from 'react';

const SortDropdown = ({ onSortChange }) => {
  return (
    <select onChange={onSortChange}>
      <option value="most_played">Most Played</option>
      <option value="least_played">Least Played</option>
      {/* Other sorting options */}
    </select>
  );
};


export default SortDropdown;
