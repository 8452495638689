import React from 'react';

const Sidebar = ({ genres }) => {
  return (
    <div className="sidebar">
      <h2>Genre</h2>
      {genres.map((genre, index) => (
        <p key={index}>{genre}</p>
      ))}
    </div>
  );
};

export default Sidebar;
