import React, { useState } from 'react';
import Sidebar from './Sidebar';
import GameList from './GameList';
import SortDropdown from './SortDropdown';

const App = () => {
  const [games, setGames] = useState([
    {
      id: 1,
      title: 'Game Title 1',
      imageUrl: 'logo192.png',
      genre: 'Adventure',
      playCount: 150,
    },
    {
      id: 2,
      title: 'Game Title 2',
      imageUrl: 'logo192.png',
      genre: 'Team Building',
      playCount: 90,
    }
  ]); // This would be fetched from an API
  const [genres, setGenres] = useState(['Adventure', 'Team Building', 'Genre3', 'Genre4', 'Genre5']); // Could also be fetched
  const [filteredGames, setFilteredGames] = useState([...games]);
  const handleSortChange = (e) => {
    const sortedGames = [...games].sort((a, b) => {
      if (e.target.value === 'least_played') {
        return a.playCount - b.playCount;
      }
      return b.playCount - a.playCount;
      // Add other sorting conditions if necessary
    });
    setGames(sortedGames)
    
    const sortedFilteredGames = [...filteredGames].sort((a, b) => {
      if (e.target.value === 'least_played') {
        return a.playCount - b.playCount;
      }
      return b.playCount - a.playCount;
      // Add other sorting conditions if necessary
    });
    setFilteredGames(sortedFilteredGames);
  };

  const setSearchTerm = (e) => {
    const filteredGamesLoc = [...games].filter((game) =>
      game.title.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredGames(filteredGamesLoc);
  };


  return (
    <div className="app">
      <div className="top-bar">
        <input
          type="text"
          placeholder="Search games..."
          onChange={setSearchTerm}
        />
        <button id="loginButton" className="login-button">Login</button>
      </div>

      <Sidebar genres={genres} />

      <div className="content">
        <div className="sort-dropdown">
        <SortDropdown onSortChange={handleSortChange} />
        </div>
        <GameList games={filteredGames} />
        <div>
        
        
        {/* <div id="g_id_onload"
          data-client_id="841454493352-iimvaqdglrkm2nggitdjvmflrv5vi0vt.apps.googleusercontent.com"
          data-context="signin"
          data-ux_mode="popup"
          data-login_uri="https://googlet1.gocg.workers.dev/"
          data-itp_support="true">
        </div>
    
        <div className="g_id_signin"
          data-type="standard"
          data-shape="rectangular"
          data-theme="outline"
          data-text="signin_with"
          data-size="large"
          data-logo_alignment="left">
        </div> */}

        </div>

      </div>
    </div>
  );
};

export default App;
