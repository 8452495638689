import React from 'react';

const GameItem = ({ game }) => {
  return (
    <div className="game-item">
      <img src={game.imageUrl} alt={game.title} />
      <h3>{game.title}</h3>
    </div>
  );
};

export default GameItem;
